import axios from 'axios';
import { createStore } from 'vuex';
import { Status } from './enums';
import { Crew, Log } from '@/crew/model';

export default createStore({
    state: {
        data: Array<Crew>(),
        logs: Array<Log>(),
        activeTab: ""
    },
    mutations: {
        SET_CREW(state, crew) {
            state.data = crew;
        },
        SET_ACTIVE_TAB(state, tab) {
            state.activeTab = tab;
        },
        SET_LOGS(state, logs) {
            state.logs = logs
        }
    },
    actions: {
        fetchCrew({ commit }) {
            axios
                .get(`crew/all`)
                .then(res => {
                    commit('SET_CREW', res.data)
                })
                .catch(err => {
                    console.log(err.response.data)
                });
        },
        fetchLogs({ commit }) {
            axios
                .get(`log/all`)
                .then(res => {
                    commit('SET_LOGS', res.data)
                })
                .catch(err => {
                    console.log(err.response.data)
                })
        },
        activateTab({ commit }, tab: string) {
            commit('SET_ACTIVE_TAB', tab);
        }
    },
    getters: {
        data: (state) => {
            return state.data
        },
        logs: (state) => {
            return state.logs
        },
        crewByStatus: (state) => (status: Status) => {
            return state.data.filter((crew) => crew.status === status)
        },
        crewByTab(state) {
            return state.data.filter((crew) => crew.status === state.activeTab)
        },
        activeTab(state) {
            return state.activeTab
        }
    },
});