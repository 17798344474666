import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import MainTabFrame from '../views/navigation/MainTabFrame.vue';
import ManagerTabFrame from '../views/navigation/ManagerTabFrame.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/onboard',
    },
    {
        path: '/manager',
        component: ManagerTabFrame,
        children: [
            {
                path: '/manager',
                redirect: '/manager/add'
            },
            {
                path: '/manager/add',
                component: () => import('../views/manager/ManagerAdd.vue')
            },
            {
                path: '/manager/edit',
                component: () => import('../views/manager/ManagerEdit.vue')
            },
            {
                path: '/manager/delete',
                component: () => import('../views/manager/ManagerDelete.vue')
            },
            {
                path: '/manager/importexport',
                component: () => import('../views/manager/ManagerSettings.vue')
            }
        ]
    },
    {
        path: '/',
        component: MainTabFrame,
        children: [
            {
                path: '',
                redirect: '/onboard',
            },
            {
                path: 'onboard',
                component: () => import('../views/board/CrewBoard.vue'),
            },
            {
                path: 'offboard',
                component: () => import('../views/board/CrewBoard.vue'),
            },
            {
                path: 'onleave',
                component: () => import('../views/board/CrewBoard.vue'),
            },
            {
                path: 'log',
                component: () => import('../views/log/LogView.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(),
    routes,
});

export default router;
