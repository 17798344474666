
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/vue';
import {
  createSharp,
  documentAttachSharp,
  personAddSharp,
  readerSharp,
  settingsSharp,
  trashSharp,
} from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TabsFrame',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const router = useRouter();

    return {
      createSharp,
      documentAttachSharp,
      personAddSharp,
      readerSharp,
      settingsSharp,
      trashSharp,
      router,
    };
  },
});
